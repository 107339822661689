.alert {
  position: fixed;
  top: 20px;
  right: 20px;
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  z-index: 1000;
}
.alert p {
  margin: 0;
}
.alert button {
  margin-top: 10px;
}
